import React, { useRef } from 'react';
import _ from 'lodash';

import { MenuContext, TooltipContext } from "@cargo/common";
import EditorsContextUI from "./editors-context-ui";
import { Badge } from "@cargo/ui-kit/badge/badge";
import { Button } from "@cargo/ui-kit/button/button";

export const UserRow = ( props ) => {

	const { user, index, editors, role, editPermission, removeUser, currentEditor, siteVersion } = props;

	const userRowRef = useRef(null);

	let canRemove = editors.length > 1 && role === 'Admin';
	// let disabled  = this.props.role == 'Viewer' || this.props.role === 'Editor' && user.role === 'Admin'
	let disabled  = currentEditor.role !== 'Admin';

	let siteAdmins = _.filter(editors, {role : 'Admin'});

	let lastAdmin = siteAdmins.length <= 1 && currentEditor.role === 'Admin';

	let displayName = user.full_name && user.full_name.length > 0 ? user.full_name : user.email;

	let tooltip = user.full_name ? user.email : undefined;

	if( !lastAdmin && currentEditor.id === user.id ){
		disabled = false;
	}

	if( lastAdmin && currentEditor.id === user.id ){
		disabled = true;
	}

	if( !currentEditor || user.role === 'Support' ){ 
		return null
	}

	return user.role ? (
		<>
			<div 
				className={`user${user.role === 'Pending' ? ' pending disabled' : ''}`}
				key = { 'user'+index }
				ref={ userRowRef }
				title={ tooltip }
			>
				<Badge 
					className={`square informational`}
					user={user}
				/>

				<div className="email-printed">
					{ displayName }
				</div>

				{user.role === 'Pending' ? ( <div className="invited">(invited)</div> ) : ( null )}

				{user.role !== 'Pending' ? 
					<MenuContext.Consumer>
						{(Menu) => 
							<Button
								label={`${user.role}`}
								type='button'
								className={`user-role-button${disabled ? ' disabled' : ''}`}
								onMouseDown = { e => {

										e.preventDefault();

										Menu.openMenu({
											innerUI: <EditorsContextUI 
												type           = "existing" 
												editor         = { user } // The user we are making changes too
												user           = { currentEditor } // The user making changes 
												allowRemove    = { canRemove }
												editPermission = { editPermission }
												removeUser     = { removeUser }
												lastAdmin      = { lastAdmin }
												role           = { role }
												siteVersion    = { siteVersion }
												hotkeys        = { props.hotkeys }
											/>,
											type: 'mouse',
											event: e
										}) 
									}
								}
							/>
						}
					</MenuContext.Consumer>
				: null}
			</div>
		</>
	) : ( <>{null}</> );
};

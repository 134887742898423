import { Component } from 'preact'
import Page from "./page";

export default class Set extends Component {

	constructor(props) {
		super(props);
	}

	render = () => <div data-container="set" data-id={this.props.id}>{
		this.props.children
			// find all children who have this set as parent
			.filter(child => child.parent === this.props.id)
			// render them
			.map(child => {
				if(child.content.page_type === "set") {
					return <Set key={child.content.id} id={child.content.id} children={this.props.children} hashPurl={this.props.hashPurl} contentPad={this.props.contentPad} />
				} else {
					return <Page key={child.content.id} id={child.content.id} position={child.position} hashPurl={this.props.hashPurl} contentPad={this.props.contentPad} />
				}
			})
	}</div>

}

import selectors from "./selectors";
import { store, history } from "./index";
import { addMiddleware as addReduxMiddleWare } from 'redux-dynamic-middlewares'
import { combineReducers } from 'redux';
import * as reducers from "./reducers";
import * as actions from "./actions";
import * as globals from "./globals";
import {galleryData} from "./components/page/gallery/index";
import { editorOverlayAPI } from "./components/overlay/editor-overlay-controller"

window.getClientAPI = () => {
	return {
		actions,
		reducers,
		selectors,
		history,
		store,
		globals,
		addReduxMiddleWare,
		combineReducers,
		galleryData,
		editorOverlayAPI
	}
}

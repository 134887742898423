import React, { Component } from 'react';
import _ from 'lodash';
import { Button } from "@cargo/ui-kit/button/button";
import { Input } from "@cargo/ui-kit/input/input";
import { MoreActions } from "@cargo/ui-kit/more-actions/more-actions";
import { Alert, AlertContext } from "@cargo/ui-kit/alert/alert";
import { TextButton } from "@cargo/ui-kit/text-button/text-button";
import { Formik, Field, Form } from 'formik';
import FormikChangeListener from "../basic-formik-change-listener";
import { ReplaceDiacritics, GetAndRemoveTld } from "./domain-helpers";
import { DomainRow } from "./domain-row";

import './domain-search.scss';

let tldList = null;

class DomainSearchClass extends Component {

	constructor(props) {
		super(props);

		this.state = {
			domainsToDisplay : this.props.domainResults ? this.props.domainResults : [],
			domainToPurchase : null,
		}

		this.tld_term = '';
		this.firstTld = null;
		this.resultsIndex = 0;
		this.formikProps = null;
		this.queryGroupAmount = 10;


	}

	componentDidMount = () => {

		const Alert = this.props.Alert;

		let tldListLoaded = tldList ? true : false;

		if( !tldList ) {

			fetch('https://cargo.site/_api/v0/domains/tldlist').then(async response => {
				tldList = await response.json();
				tldListLoaded = true;
			}).catch((err) =>{
				console.warn("Error fetching TLD List", err)
			});

		} else {
			tldListLoaded = true;
		}

		window.addEventListener('clear-c3-domain-search', this.clearSearchResults );

	}

	componentWillUnmount = () => {

		window.removeEventListener('clear-c3-domain-search', this.clearSearchResults )

	}

	setWindowView = ( view ) => {
		this.setState({ currentView: view })
	}

	render() {

		let initialFormValue = this.props.domainResults?.[0] ? this.props.domainResults[0].query.term : '';

		return (

		<Formik 
			initialValues={{
				domainSearch: initialFormValue,
			}}
		>
		{(props) => {
			this.formikProps = props;
			return(
			<>
				<FormikChangeListener  onChange={(changes) => this.onChange(changes)} />
					<div id="domain-search" className="ui-group">

					<AlertContext.Consumer>
						{(Alert) => (
							<>

							<div className="grid-columns-auto-square">
							<div className="text-input">
								<Field
									component={Input}
									name="domainSearch"
									placeholder={this.props.placeholder}
									noBars={true}
									focusOnInit={true}
									onKeyPress={(e)=> { 
											if( e && e?.key === 'Enter' ) {
												e.preventDefault();
												e.stopPropagation();
												this.SubmitSearch( props.values.domainSearch, Alert, props );												 			
											} 
										}}
									// className={`${this.state.error.indexOf('website_title') !== -1 ? 'error' : ''}`}
								/>
							</div>
								<Button 
									className={`square search-button`} 
									icon={
									<svg x="0px" y="0px" viewBox="0 0 35 35" enableBackground="new 0 0 35 35">
										<path fill="#FFFFFF" d="M32.682,28.888l-6.421-6.421c1.634-2.48,2.459-5.538,2.085-8.797C27.663,7.706,22.878,2.856,16.921,2.107
										C8.299,1.024,1.019,8.307,2.108,16.931c0.753,5.956,5.605,10.736,11.57,11.416c3.257,0.371,6.311-0.454,8.79-2.086l6.421,6.421
										c0.246,0.246,0.645,0.246,0.891,0l2.903-2.903C32.928,29.533,32.928,29.134,32.682,28.888z M5.025,15.218
										c0-5.62,4.573-10.193,10.193-10.193c5.62,0,10.192,4.572,10.192,10.193c0,5.62-4.572,10.193-10.192,10.193
										C9.598,25.41,5.025,20.838,5.025,15.218z"></path>
									</svg>
									}
									onMouseDown={(e)=>{
										this.SubmitSearch( props.values.domainSearch, Alert, props );
									}}
								/>
							</div>


							{/* DOMAIN SINGLETON */}
							{ this.state.domainsToDisplay.length > 0 ? (
							<>
							<div className="search-results">
								{this.state.domainsToDisplay.map((domain, index) => {
									// If first TLD 
									if( domain.tld === this.firstTld && index !== 0 ){
										return ( null )
									}

									return (
										<DomainRow 
											domain={ this.SanitizeQuery( domain.query.query ) } 
											tld={ domain.tld } 
											chooseDomain={ (domainModel, tld)=> {
												domainModel.tld = tld;
												this.props.chooseDomain( domainModel, props ) 
											}}
											key={ index + domain.tld + domain.query.term }
										/>
									)
								})}
							</div>
							<MoreActions className="space-between">
							{ this.resultsIndex >= tldList?.length ? ( <div></div> ) : (

								<TextButton
									className="show-more font-size-default"
									onMouseDown={() => { 
										this.showNextResultsPage(props) 
									}}
									label={`↓ More results`}
								/>

							)}

							{/* <TextButton
								onMouseDown={() => { this.clearSearchResults(props) }}
								label={`Cancel`}
								className="font-size-default"
							/> */}

							</MoreActions>
							<div className='uiWindow-spacer tall'></div>
							</>
							) : ( null )}


							</>	
						)}
					</AlertContext.Consumer>
				</div>
			</>
		)}}
		</Formik>

		)
	}

	onChange = (changes) => {
		// Do nothing...
	}
	
	SubmitSearch = ( searchTerm, Alert, Form ) => {

		let tldIndex = null;
		let initialResultsCount = this.queryGroupAmount;
		this.firstTld = null;
		this.resultsIndex = 0;

		// Prevent re-search same term 
		if( this.currentQuery === this.SanitizeQuery( searchTerm ) ){
			return
		}

		this.setState({ domainsToDisplay : [] });

		if( this.props.setDomainResults ){
			this.props.setDomainResults( [] );
		}

		// if input isn't empty
		if( searchTerm.trim() != '') {

			this.currentQuery = this.SanitizeQuery( searchTerm );

			// if submitting a domain with cargo.site
			if ( this.currentQuery.match(/cargo\.site/g) ) {

	    		Alert.openModal({
	    		    header: 'The Personal Domain cannot be your Cargo URL. If you’re looking to change your Cargo URL, do so from Publish Settings.',
	    		    type: 'notice',
	    		    HotKeyProxy: this.props.hotkeys,
	    		    onConfirm: (options) => {
	    		    },
	    		    onDeny: (options) => {
	    		    }
			   });

				return false;
			}

			// Update the form with the sanitized query
			Form.setFieldValue('domainSearch', this.currentQuery );

			this.currentQuery = {
				query : this.tld_term.query, 
				term : this.currentQuery, 
				tld : this.tld_term?.tld[0]?.replace(/\./g, '')
			}

			if( this.currentQuery.tld ){
				tldIndex = tldList.findIndex((o)=> { return o.tld === this.currentQuery.tld });
			}

			if( this.resultsIndex && resultsIndex > 6 ){
				initialResultsCount = 5;
			}

			let results = tldList.slice(this.resultsIndex, this.resultsIndex+initialResultsCount);
			this.resultsIndex = this.resultsIndex + initialResultsCount;
			_.map(results, (result) => { result.query = this.currentQuery });

			if( tldIndex ){
				this.firstTld = this.currentQuery.tld;
				let firstResult = tldList.slice(tldIndex, tldIndex+1);
				firstResult[0].query = this.currentQuery
				results.unshift(firstResult[0]);
			}

			this.setState({ domainsToDisplay : results });

			if( this.props.setDomainResults ){
				this.props.setDomainResults( results );
			}
			// Trigger search.

		}
		return false;
	}

	clearSearchResults = ( props ) => {

		this.resultsIndex = 0;
		this.currentQuery = null;

		this.setState({ domainsToDisplay : [] });
		if( this.props.setDomainResults ){
			this.props.setDomainResults( [] );
		}

		if( props.setFieldValue ){
			props.setFieldValue('domainSearch', '');
		}

		if( this.formikProps.setFieldValue ){
			this.formikProps.setFieldValue('domainSearch', '');
		}

	}

	showNextResultsPage = (Form) => {
		let limit = tldList.length;
		let indexIncriment = null;


		if( this.props.domainResults && this.resultsIndex !== this.props.domainResults?.length ){
			this.resultsIndex = this.props.domainResults.length;
		}

		if( this.resultsIndex <= limit && this.resultsIndex+this.queryGroupAmount <= limit ) {
			indexIncriment = this.queryGroupAmount;
		} else if ( this.resultsIndex !== limit && this.resultsIndex < limit ){
			indexIncriment = this.resultsIndex - limit;
		}

		// Return if we can't agree on an incriment.
		if ( !indexIncriment ){ return }

		let results = tldList.slice(this.resultsIndex, this.resultsIndex+indexIncriment);
		this.resultsIndex = this.resultsIndex + indexIncriment;

		if( !this.currentQuery && this.props?.domainResults?.[0]?.query ){
			// This happens when we go back and forth between the domain search in a window and the payment form. 
			// Should not happen if the payment form is opened in a different window from the search content. 
			_.map(results, (result)=>{ result.query = this.props.domainResults[0].query });
		} else {
			_.map(results, (result)=>{ result.query = this.currentQuery });
		}

		let paginatedList = [...this.state.domainsToDisplay, ...results]

		this.setState({ domainsToDisplay : paginatedList });

		if( this.props.setDomainResults ){
			this.props.setDomainResults( paginatedList );
		}

	}

	SanitizeQuery = (query) => {
		query = query.toLowerCase().trim();

		// Remove HTTP(s)
		query = query.replace(/^https?:\/\//, "");

		// Remove www
		query = query.replace(/^www\./, "");

		// Spaces to dashes
		query = query.replace(/\s/g, "-");			

		// Replace diacritic characters
		query = ReplaceDiacritics(query);

		// Remove non-alpha numeric
        query = query.replace(/[^0-9a-z-\.]/g, "");
        // TLD stripped query
        this.tld_term = GetAndRemoveTld( query, tldList );

        // Remove any periods from the removed tld query
        query = this.tld_term.query.replace(/\./g, "") + this.tld_term.tld;

		return query;
	}



}


export const DomainSearch = DomainSearchClass;



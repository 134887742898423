import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { helpers } from "@cargo/common";
import { CRDTState } from "../globals";
import _ from "lodash";

export default function(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SITE_MODEL_FULFILLED: 
		case actionTypes.FETCH_SITE_PACKAGE_FULFILLED: 

			let data = action.payload.data;

			if(action.type === actionTypes.FETCH_SITE_PACKAGE_FULFILLED) {
				data = data.site;
			}

			if(data) {
				
				// If we are in site preview, set the domain active to false
				if(helpers.isSitePreview) {
					data.domain_active = false;
				}

				if(!helpers.isServer) {
					try {
						// this can be used to admin to quick connect
						// to the CRDT server
						localStorage.setItem('c3-site', JSON.stringify({
							id: data.id,
							host: window.location.host
						}));
					} catch(e) { console.error(e); }
				}

				return {
					...state,
					...data,
					// assume css coming from the API is published
					crdt_state: CRDTState.Published
				};

			}

		break;
	}

	return state;
};
import _ from 'lodash';
import { helpers } from '@cargo/common';

const embedsNS = {

	handledEmbeds: [],

	embedsIniting: [],

	embedScriptsCache: {},

 	songKickWidgets: {},

	embedChangeObserver: helpers.isServer ? null : new MutationObserver(mutations => {

		mutations.forEach(mutation => {
			// handle embed being emptied by something
			if(mutation.removedNodes.length > 0 && mutation.target.children.length === 0) {
				embedsNS.add(mutation.target);
			}
		})

	}),

	loadEmbedScript: (url, onload) => {

		if(embedsNS.embedScriptsCache[url]) {
			return embedsNS.embedScriptsCache[url];
		}

		const promise = new Promise(resolve => {

			const script = document.createElement('script');
			script.setAttribute('src', url);
			script.onload = resolve;

			document.head.appendChild(script);

		});


		embedsNS.embedScriptsCache[url] = promise;

		return promise;

	},

	initializeBandsInTown: function(callback, version){

		var url = 'https://widget.bandsintown.com/main.min.js';

		if(version === 'v3') {
			url = 'https://widgetv3.bandsintown.com/main.min.js'
		}

		embedsNS.loadEmbedScript(url).then(function(){

			if(BIT.widgetInitializer) {
				callback();
			} else {
				BIT.onReady = callback;
			}

		});

	},

	findEmbedsIn: function(node){

		if(!node) {
			return;
		}

		_.each(node.querySelectorAll('cargo-embed'), embedsNS.add, embedsNS);

	},

	add: function(node){

		if(!node || node.nodeType !== Node.ELEMENT_NODE) {
			return;
		}

		var type = node.getAttribute('data-type');

		if( !type || (embedsNS.handledEmbeds.includes(node) && node.children.length > 0) ) {
			return;
		}

		switch(type) {
			case 'tweet': 
				embedsNS.handleTwitterEmbed(node);
				break;
			case 'twitter-timeline': 
				embedsNS.handleTwitterTimelineEmbed(node);
				break;
			case 'bandsintown': 
				embedsNS.handleBandsIntownEmbed(node);
				break;
			case 'songkick': 
				embedsNS.handleSongkickEmbed(node);
				break;
			case 'opentable': 
				embedsNS.handleOpentableEmbed(node);
				break;
			case 'typeform': 
				embedsNS.handleTypeFormEmbed(node);
				break;
		}

	},

	handleTwitterEmbed: function(node){

		var id = node.getAttribute('data-id');

		if(!id || id === "") {
			return;
		}

		if(embedsNS.embedsIniting.includes(node)) {
			return;
		}

		embedsNS.embedsIniting.push(node);

		embedsNS.loadEmbedScript('https://platform.twitter.com/widgets.js').then(() => {

			node.innerHTML = '';

			twttr.widgets.createTweet(
				id,
				node
			).then(function (el) {

				if(el) {
					el.setAttribute('contenteditable', 'false');
					embedsNS.embedChangeObserver?.observe(node, { childList: true, attributes: true });
				}

				embedsNS.embedsIniting = embedsNS.embedsIniting.filter(n => n !== node);

			});

		});

		embedsNS.handledEmbeds.push(node);

	},

	handleTwitterTimelineEmbed: function(node){

		if(embedsNS.embedsIniting.includes(node)) {
			return;
		}

		embedsNS.embedsIniting.push(node);

		try {
			var source = JSON.parse(node.getAttribute('data-source'));
		} catch(e) {
			return;
		}

		if(typeof source == "number") {
			source = node.getAttribute('data-source');
		}

		var options;

		if(node.hasAttribute('data-options')){
			try {
				options = JSON.parse(node.getAttribute('data-options'));
			} catch(e) {
			}
		}

		embedsNS.loadEmbedScript('https://platform.twitter.com/widgets.js').then(() => {

			node.innerHTML = '';

			twttr.widgets.createTimeline(
				source,
				node,
				options
			).then(function (el) {

				if(el){
					el.setAttribute('contenteditable', 'false');
					embedsNS.embedChangeObserver?.observe(node, { childList: true, attributes: true });
				}
			
				embedsNS.embedsIniting = embedsNS.embedsIniting.filter(n => n !== node);
			
			});

		});

		embedsNS.handledEmbeds.push(node);

	},

	handleOpentableEmbed: function(node) {

		if(!node.ownerDocument.contains(node)) {
			return;
		}

		const fakeEmbedScript = document.createElement('opentable-embed-anchor');
		fakeEmbedScript.src = node.getAttribute('data-src');

		node.appendChild(fakeEmbedScript);

		fetch(node.getAttribute('data-src'))
			.then(result => result.text())
			.then(embedScriptAsText => {

				// assign an ID so it can be retrieved by the eval'd code
				fakeEmbedScript.id = _.uniqueId('opentable-');

				// scope the embed code to the cargo-embed element only
				embedScriptAsText = embedScriptAsText.replace('getElementsByTagName("script")', 'querySelectorAll("opentable-embed-anchor#'+fakeEmbedScript.id+'")');

				// execute the modified embed code
				window.eval(embedScriptAsText);

				// kill the fake embed anchor
				fakeEmbedScript.remove();

			})

		// Re-init embed if something external wipes it
		embedsNS.embedChangeObserver?.observe(node, { childList: true, attributes: true });
		embedsNS.handledEmbeds.push(node);

	},

	handleBandsIntownEmbed: function(node){

		/**
		<script charset="utf-8"
		src="https://widget.bandsintown.com/main.min.js"></script><a
		class="bit-widget-initializer" data-artist-name="Traverse Town"
		data-display-local-dates="false" data-display-past-dates="true"
		data-auto-style="false" data-text-color="#000000" data-link-color="#2F95DE"
		data-popup-background-color="#FFFFFF" data-background-color="#FFFFFF"
		data-display-limit="15" data-link-text-color="#FFFFFF"></a>
		 */
		
		embedsNS.initializeBandsInTown(() => {

			node.innerHTML = '';

			var options = {};
			var widgetOptions = {};

			_.each(node.attributes, function(attr) {
				if (/^data-/.test(attr.name)) {
					if(attr.name !== "data-type") {

						var underscoredValue = attr.name.substr(5).replace(/-/g, '_');
						options[underscoredValue] = attr.value;

						var camelCasedValue = underscoredValue.split('_').map(function(valuePart, i) {
							if(i > 0) {
								return valuePart.charAt(0).toUpperCase() + valuePart.slice(1);
							}
							return valuePart;
						}).join('');

						widgetOptions[camelCasedValue] = attr.value;
					}
				}
			});

			var target = document.createElement('a');

			target.setAttribute('id', 'bit-widget-' + _.uniqueId());
			target.setAttribute('class', 'bit-widget-initializer');

			_.each(options, function (value, key) {
				target.setAttribute('data-' + key.replace(/_/g, '-'), value);
			});

			node.appendChild(target);

			if(helpers.isAdminEdit) {

				// prevent mouse events and editing of the widget in the admin.
				var observer = new MutationObserver(function(mutations){

					_.each(mutations, function(mutation){

						if (mutation.type == 'childList') {
							
							mutation.addedNodes.forEach(function(child){
								
								if(child && child.nodeType === Node.ELEMENT_NODE) {
									child.setAttribute('contenteditable', false);
									
									if(child.classList.contains('bit-widget-container')) {
										child.style.pointerEvents = 'none';
										observer.disconnect();
									}
								}

							});

						}
					});

				});

				observer.observe(node, { childList: true });
				
			}

			BIT.widgetInitializer.append(target, widgetOptions);

			// Re-init embed if something external wipes it
			embedsNS.embedChangeObserver?.observe(node, { childList: true });
			embedsNS.handledEmbeds.push(node);
			
		}, node.getAttribute('data-bit-widget-version'));

	},

	handleSongkickEmbed: function(node){

		if(embedsNS.embedsIniting.includes(node)) {
			return;
		}

		embedsNS.embedsIniting.push(node);

		/*
		<a href="https://www.songkick.com/artists/9044964" class="songkick-widget" data-theme="light" data-track-button="on" data-font-color="#fff" data-background-color="transparent">Cyberattack tour dates</a>
		<script src="//widget.songkick.com\/9044964\/widget.js"></script>
		*/

		var options = {};

		_.each(node.attributes, function(attr) {
			if (/^data-/.test(attr.name)) {
				if(attr.name !== "data-type") {
					var underscoredValue = attr.name.substr(5).replace(/-/g, '_');
					options[underscoredValue] = attr.value;
				}
			}
		});

		var target = document.createElement('a');

		target.setAttribute('class', 'songkick-widget');
		target.setAttribute('href', 'https://www.songkick.com/artists/' + options.id);
		target.setAttribute('contenteditable', 'false');

		_.each(options, function (value, key) {
			target.setAttribute('data-' + key.replace(/_/g, '-'), value);
		});

		node.appendChild(target);

		var url = 'https://widget.songkick.com/' +options.id+ '/widget.js';

		if(embedsNS.songKickWidgets[options.id] === undefined) {
			embedsNS.songKickWidgets[options.id] = 0;
		} else {
			url = url + '?' + ++embedsNS.songKickWidgets[options.id];
		}

		embedsNS.loadEmbedScript(url).then(() => {
			embedsNS.embedsIniting = embedsNS.embedsIniting.filter(n => n !== node);

			if(node.children.length === 0) {
				// try again
				embedsNS.add(node);
			}

		});

		// Re-init embed if something external wipes it
		embedsNS.embedChangeObserver?.observe(node, { childList: true });
		embedsNS.handledEmbeds.push(node);

	},

	handleTypeFormEmbed: function(node) {
		
		if(embedsNS.embedsIniting.includes(node)) {
			return;
		}

		embedsNS.embedsIniting.push(node);

		embedsNS.loadEmbedScript('https://embed.typeform.com/next/embed.js').then(() => {

			const options = {};

			_.each(node.attributes, function(attr) {
				if (attr.name.startsWith('data-tf')) {
					options[_.camelCase(attr.name.replace('data-tf', ''))] = attr.value;
				}
			});

			options.container = node;
			// If we need to support this later on: convert this string to an object
			delete options.iframeProps;

			// wipe any old stuff
			node.innerHTML = '';

			window.tf.createWidget(options.widget, options);

			// set the original embed style on our widget
			node.children[0]?.setAttribute('style', node.getAttribute('data-style'));
			
			// sometimes it takes a moment for the final child node to be present
			setTimeout(() => {
				node.children[0]?.setAttribute('style', node.getAttribute('data-style'));
			}, 100);
			setTimeout(() => {
				node.children[0]?.setAttribute('style', node.getAttribute('data-style'));
			}, 1000);

			embedsNS.embedsIniting = embedsNS.embedsIniting.filter(n => n !== node);

		});

		// Re-init embed if something external wipes it
		embedsNS.embedChangeObserver?.observe(node, { childList: true });
		embedsNS.handledEmbeds.push(node);

	},

	remove: function(){

	}

}

export default embedsNS;
import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { actions } from "../../actions";
import { Alert, AlertContext } from "@cargo/ui-kit/alert/alert";
import { ContextMenuButton, ContextMenuCheckbox } from "@cargo/common";

const EditorsContextUI = (props) => {

	const handleCheckboxChange = (changes) => {
		if( props.type === 'new' ){
			props.setInvitePermissions( 'new', changes )
		} else {
			props.editPermission(props.editor, changes)
		}

	}

	if( props.siteVersion === 'Cargo2' && props.allowRemove === true ){
		const c2Label        = props.user?.id !== props.editor?.id ? "Remove..." : "Leave Site...";
		const c2Alert        = props.user?.id !== props.editor?.id ? 'Remove Editor?' : 'Leave Site?';
		const editorToRemove = props.user?.id !== props.editor?.id ? props.editor : props.user;
		return (
			<AlertContext.Consumer>
				{(Alert) => 
					<ContextMenuButton 
						label={ c2Label }
						onPointerUp = { e => {

							Alert.openModal({
								header: c2Alert,
								type: 'confirm',
								ignoreUnmount: true,
								HotKeyProxy: props.hotkeys,
								onConfirm: () => {
									props.removeUser( editorToRemove )
								}
							})

						}}
					/>}
			</AlertContext.Consumer>
		)
	}

	if( props.type === 'new' ){
		return (
			<>

				<ContextMenuCheckbox
					label="Admin"
					name="permission"
					value={ props.editor.access === 'Admin' }
					onPointerUp = { e => {
						if( props.role !== 'Admin'  ){ return }
						handleCheckboxChange('Admin')
					}}
					classList={props.role === 'Admin' ? '' : ' disabled' }
				/>

				<ContextMenuCheckbox
					label="Editor"
					name="permission"
					value={ props.editor.access === 'Editor' }
					classList={props.role === 'Admin' || props.role === 'Editor' ? '' : ' disabled' }
					onPointerUp = { e => {
						if( props.role !== 'Admin' && props.role !== 'Editor' ){ return }
						handleCheckboxChange('Editor')
					}}
				/>

				<ContextMenuCheckbox
					label="Viewer"
					name="permission"
					classList={props.role === 'Admin' || props.role === 'Editor' ? '' : ' disabled' }
					value={ props.editor.access === 'Viewer' }
					onPointerUp = { e => {
						if( props.role !== 'Admin' && props.role !== 'Editor' ){ return }
						handleCheckboxChange('Viewer')
					}}
				/>

			</>
		);
	} else {
		return (
			<>
				{ props.user.role === 'Admin' ? (
					<>
						<ContextMenuCheckbox
							label="Admin"
							name="permission"
							value={ props.editor.role === 'Admin' }
							onPointerUp = { e => {
								handleCheckboxChange('Admin')
							}}
							classList={props.role === 'Admin' ? '' : ' disabled' }

						/>

						<ContextMenuCheckbox
							label="Editor"
							name="permission"
							value={ props.editor.role === 'Editor' }
							onPointerUp = { e => {
								handleCheckboxChange('Editor')
							}}
						/>

						<ContextMenuCheckbox
							label="Viewer"
							name="permission"
							value={ props.editor.role === 'Viewer' }
							onPointerUp = { e => {
								handleCheckboxChange('Viewer')
							}}
						/>
					</>
				) : ( null )}


				{ props.allowRemove === true && props.user?.id !== props.editor?.id ? 
					<>
						<hr/>


						<AlertContext.Consumer>
						    {(Alert) => 
						    	<ContextMenuButton
									label="Remove..."
						        	onPointerUp={ (e) => {
				            			Alert.openModal({
						            		header: 'Remove Editor?',
						            		type: 'confirm',
						            		ignoreUnmount: true,
						            		onConfirm: () => {
												props.removeUser( props.editor )
					            			},
					            			HotKeyProxy: props.hotkeys
				            			})
					        		}}
						    	/>}
						</AlertContext.Consumer>

						
					</>
				: null }
				
				{ !props.lastAdmin && props.user.id === props.editor.id ? (
					<>
						{ props.user.role === 'Admin' ? ( <hr/> ) : ( null )} 
						<AlertContext.Consumer>
						    {(Alert) => 
								<ContextMenuButton 
									label="Leave Site..." 
									onPointerUp = { e => {

				            			Alert.openModal({
						            		header: 'Leave Site?',
						            		type: 'confirm',
						            		ignoreUnmount: true,
						            		HotKeyProxy: props.hotkeys,
						            		onConfirm: () => {
												props.removeUser( props.user )
					            			}
				            			})

									}}
						    	/>}
						</AlertContext.Consumer>
					</> 
				) : ( null )}
				

			</>
		);
	}

};

export default EditorsContextUI

import React, { Component } from 'react';
import _ from 'lodash';

import '../css/subscription-info-sticky.scss';

class SubscriptionInfoSticky extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		return <div className="info-card" style={{lineHeight: '1.3'}}>
			<span className="text-block">
			{/* <img className="sp" src={`${PUBLIC_URL}/images/sp.png`}/> */}
			{/* In our previous version, each public site required a separate payment. We have changed this model to allow for multiple public sites under one payment, without increased pricing.
			<br/><br/>With our new version, a single Cargo Subscription allows a user to make unlimited(!) public sites using “cargo.site” domains (as in: <i>site1.cargo.site</i>, <i>site2.cargo.site</i>, <i>site3.cargo.site</i>, etc.). Yes,&nbsp;unlimited!
			<br/><br/>As well, a user still receives one free custom domain. Additional sites with custom domains are priced affordably at $2/mo (when billed yearly) or $3/mo (when billed monthly).
			<br/><br/>We are very excited about being able to offer this new multi-site model and sincerely hope you are too. For more info or to ask a question, <span className="message-support" onClick={this.openSupportWindow}>message support</span>.
			<br/><br/> */}

				In our previous version, each public site required a separate payment. We have changed this model to allow for <span style={{fontWeight: 800}}>multiple public sites under one payment, without increased pricing.</span>
                
                <br/><br/><i>And</i> with our new version, a single Cargo Subscription allows a user to make <span style={{fontWeight: 800}}>unlimited(!) public sites</span> using “cargo.site” domains (as in: <i>site1.cargo.site</i>, <i>site2.cargo.site</i>, <i>site3.cargo.site</i>, etc.). Yes,&nbsp;unlimited!


                <br/><br/>As well, a user still receives one free custom domain connection. Connecting custom domains to additional sites is priced at $2/mo (when billed yearly) or $3/mo (when billed monthly). Please note: in our previous version additional custom domain connections required another full upgrade. <span style={{fontWeight: 800}}>(We have lowered the costs of <i>additional custom domain connections</i> by 85%.)</span>


                <br/><br/>We are very excited about being able to offer this new, budget conscious, multi-site model and sincerely hope you are&nbsp;too.

			</span>
		</div>

	}

	openSupportWindow = (e) => {
		
		e.preventDefault();
		const buttonPos = e.currentTarget?.getBoundingClientRect();
		// Vertically centered  -- few px down. doesn't conflict with other windows.
		// this.props.updateAdminState?.({ helpWindowVisible: true });

		var supportWindowHeight = 572; //it's display none so we can't measure. Magic number the value
		var windowHeight = window.innerHeight;
		var verticalCenter = (windowHeight - supportWindowHeight) / 2;
		
		buttonPos.y = verticalCenter;
		buttonPos.x = buttonPos.x + 100;
		// add above-hoisted class and set position
		this.props.updateUIWindow('help-window', {extraClass: 'above-hoisted', buttonPos: _.merge({}, buttonPos)})

	}

	componentWillUnmount = () => {
		// remove above-hoisted class
		this.props.updateUIWindow('help-window', {extraClass: ''})
	}

	closeSticky = (e) => {

		if (e?.target && e?.target.closest('.info-card')) return;

		this.props.removeUIWindow(uiWindow => {
			return uiWindow.id === 'subscription-info-sticky'
		});
	}

	componentDidUpdate = (prevProps, prevState) => {
		
		if(     prevProps.uiWindows.byId['subscription-window']
			&& !this.props.uiWindows.byId['subscription-window'] 
			&& this.props.uiWindows.byId['subscription-info-sticky']
		){
			this.closeSticky(null);
		}
	}

}

export default SubscriptionInfoSticky;
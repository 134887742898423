export const letters = {
	'0': '⓿',
	'1': '❶',
	'2': '❷',
	'3': '❸',
	'4': '❹',
	'5': '❺',
	'6': '❻',
	'7': '❼',
	'8': '❽',
	'9': '❾',
	'a': '🅐',
	'b': '🅑',
	'c': '🅒',
	'd': '🅓',
	'e': '🅔',
	'f': '🅕',
	'g': '🅖',
	'h': '🅗',
	'i': '🅘',
	'j': '🅙',
	'k': '🅚',
	'l': '🅛',
	'm': '🅜',
	'n': '🅝',
	'o': '🅞',
	'p': '🅟',
	'q': '🅠',
	'r': '🅡',
	's': '🅢',
	't': '🅣',
	'u': '🅤',
	'v': '🅥',
	'w': '🅦',
	'x': '🅧',
	'y': '🅨',
	'z': '🅩',
}


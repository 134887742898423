import { Observable } from 'lib0/observable';

// store a reference to window.fetch in case it gets overwritten externally. This could
// be abused to intercept the auth token
const fetch = typeof window !== 'undefined' && window.fetch;

// auth data stored here is inaccessible to anything outside this module.
let authToken = null;

// main fetch method
const runFetch = async (fetchInstance, url, config) => {

	config.headers = config.headers || {};

	config.headers['Accept'] = 'application/json, text/plain, */*';

	// convert withCredentials option to credentials: 'include'
	if(config.withCredentials && !config.hasOwnProperty('credentials')) {
		delete config.withCredentials;
		config.credentials = 'include';
	}

	// set auth headers if we have a token
	if(fetchInstance.hasAuthorization()) {
		config.headers['Authorization'] = 'Bearer ' + authToken;
	}

	let result;

	try {
		result = await fetch(url, config);
	} catch (error) {
		// TypeError: Failed to fetch
		fetchInstance.emit('error', [error]);
		throw error;
	}

	if(config.responseType === "blob") {
		
		result.data = await result.blob();

	} else {

		// Assume return data is JSON for now.
		const text = await result.text();

		// store the data on the result
		try {
			// Assume the result is JSON
			result.data = JSON.parse(text);
		} catch(e) {
			// fall back to text if the response can't be parsed as JSON
			result.data = text;
		}

	}

	if(result.status < 200 || result.status >= 300) {
		fetchInstance.emit('rejected', [result]);
		throw result;
	}

	// return the response
	return result;

}

// public facing class
class CargoFetcher extends Observable {

	setAuthorization = token => {
		authToken = token;
	}

	hasAuthorization = () => {
		return typeof authToken === "string" && authToken.length > 0;
	}

	get = (url, config = {}) => {

		config.method = "GET";

		return runFetch(this, url, config);

	}

	post = (url, data, config = {}) => {

		config.method = "POST";
		
		if(data) {
			try {
				config.body = typeof data === "string" ? data : JSON.stringify(data);
				
				config.headers = config.headers || {};
				config.headers['Content-Type'] = "application/json";
			} catch(e) {
				console.error(e);
			}
		}

		return runFetch(this, url, config);

	}

	put = (url, data, config = {}) => {

		config.method = "PUT";
		
		if(data) {
			try {
				config.body = typeof data === "string" ? data : JSON.stringify(data);
				
				config.headers = config.headers || {};
				config.headers['Content-Type'] = "application/json";
			} catch(e) {
				console.error(e);
			}
		}

		return runFetch(this, url, config);

	}

	patch = (url, data, config = {}) => {

		config.method = "PATCH";
		
		if(data) {
			try {
				config.body = typeof data === "string" ? data : JSON.stringify(data);
				
				config.headers = config.headers || {};
				config.headers['Content-Type'] = "application/json";
			} catch(e) {
				console.error(e);
			}
		}

		return runFetch(this, url, config);

	}

	delete = (url, data, config = {}) => {

		config.method = "DELETE";

		if(data) {
			try {
				config.body = typeof data === "string" ? data : JSON.stringify(data);
				
				config.headers = config.headers || {};
				config.headers['Content-Type'] = "application/json";
			} catch(e) {
				console.error(e);
			}
		}

		return runFetch(this, url, config);

	}

}

// default instance
export default new CargoFetcher();
import { Component, createRef, isValidElement, Fragment } from "preact";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../actions';
import _ from 'lodash';
import selectors from '../selectors';
import { PinManagerContext, updateHeights as updatePinContextHeights } from './pin-context';
import PageInfoContextProvider from './page/page-info-context';
import ScrollContextProvider from "./page/scroll-element";
import { CustomElementHost } from './page/register'
import UsesWatcher from './page/uses/uses';
import windowInfo from "./window-info"
import Backdrop from './page/backdrop/index'
import { helpers } from "@cargo/common";
import Password from './page/password';
import { getMobileOffsetsString, shallowEqual } from "../helpers";

const pageMap = new WeakMap();
const bodycopyMap = new WeakMap();
const pageContentMap = new WeakMap();
const pageLayoutMap = new WeakMap();

let resizeObserver;
let aboveViewportObserver;
let viewportBoundaryObserver;

const onAboveViewPortCallback = entries => {
	entries.forEach(function(entry){
		if( pageMap.has(entry.target) ){
			pageMap.get(entry.target).onAboveViewPort(entry)
		}
	});
}

let aboveViewportHeightCompensationRequired = 0;

if(!helpers.isServer) {

	resizeObserver = new ResizeObserver(function(entries){

		const updatedPageHeights = {};

		// flush above viewport records before handling resizes
		onAboveViewPortCallback(aboveViewportObserver.takeRecords())

		entries.forEach(function(entry){

			const pageComponent = pageMap.get(entry.target);

			if( pageComponent ){
				pageComponent.onResize(entry.contentRect)
				updatedPageHeights[pageComponent.props.id] = entry.contentRect.height;
			}

			if( bodycopyMap.has(entry.target) ){
				bodycopyMap.get(entry.target).onBodyCopyResize(entry.contentRect.width)
			}

			if( pageContentMap.has(entry.target) || pageLayoutMap.has(entry.target) ){

				var style = window.getComputedStyle(entry.target);

				let top = parseFloat(style.getPropertyValue('margin-top')) +
					parseFloat(style.getPropertyValue('padding-top')) +
					parseFloat(style.getPropertyValue('border-top'));

				let bottom = parseFloat(style.getPropertyValue('margin-bottom')) +
					parseFloat(style.getPropertyValue('padding-bottom')) +
					parseFloat(style.getPropertyValue('border-bottom'));

				if( pageContentMap.has(entry.target) ){

					const pageComponent = pageContentMap.get(entry.target);

					if(
						pageComponent.state.pageContentPad.top !== top
						|| pageComponent.state.pageContentPad.bottom !== bottom
					) {

						pageComponent.setState({
							pageContentPad: {
								top, bottom
							}
						})

					}

				} else {

					const pageComponent = pageLayoutMap.get(entry.target);

					if(
						pageComponent.state.pageLayoutPad.top !== top
						|| pageComponent.state.pageLayoutPad.bottom !== bottom
					) {
						
						pageComponent.setState({
							pageLayoutPad: {
								top, bottom
							}
						})

					}
				}

			}

		});

		// compensate for content height changes above the viewport
		if(aboveViewportHeightCompensationRequired !== 0) {
			document.scrollingElement.scrollTop += aboveViewportHeightCompensationRequired;
			aboveViewportHeightCompensationRequired = 0;
		}

	});

	aboveViewportObserver = new IntersectionObserver(onAboveViewPortCallback, {
		root: document,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0,1]
	});

	viewportBoundaryObserver = new IntersectionObserver((entries, o) => {

		entries.forEach(function(entry){
			if( pageMap.has(entry.target) ){
				pageMap.get(entry.target).onViewportBoundaryIntersection(entry)
			}
		});

	}, {
		root: document,
		rootMargin: Math.max(screen.height * 6, 2500) + 'px',
		threshold: [0, 1]
	});

}

class SiteImages extends Component {

	constructor(props) {
		
		super(props);


		this.bodycopyRef = createRef();
		this.pageContentRef = createRef();
		this.pageRef = createRef();
		this.pageLayoutRef = createRef();
		this.galleryRef = createRef();

		this.lastBodycopyRef = createRef();
		this.lastPageContentRef = createRef();
		this.lastPageRef = createRef();
		this.lastPageLayoutRef = createRef();

		this.currentHeight = 0;
		this.state = {
			resizeParentWidth: '100%',
			mobileOffsetsString: null,
			pageContentPad: {
				top: 0,
				bottom:0,
			},
			pageLayoutPad: {
				top: 0,
				bottom: 0,
			},

			authorized: localStorage.getItem('images-include-offline'),
			imageSort: 'forward',
			windowHeight: windowInfo.data.window.h,
			fullyRender: true,
			lastPageLayoutHeight: 100
		}

		this.localStyleRef = createRef();
		this.scrollingElement = {current: helpers.isServer ? null : window};

	}

	setMobileOffsets = () => {

		// if not mobile, clear the styles
		if (!this.props.isMobile) {
			this.setState({mobileOffsetsString: null})
			return;
		}

		// if no global stylesheet, then we can't calculate offsets
		if(!this.localStyleRef.current) return;

		const string = getMobileOffsetsString(this.localStyleRef.current.sheet, [
			{
				properties: [
					//'padding',
					'padding-top',
					'padding-right',
					'padding-bottom',
					'padding-left',
				],
				denyList: [
					'ul',
					'ol',
					'li',
					'h1',
					'h2',
					'h3',
					'h4',
					'h5',
					'h6',
					'sub',
					'sup',
					'small-caps'
				]
			}
		])

		this.setState({mobileOffsetsString: string})
	}

	onBodyCopyResize = (width) => {

		width = parseInt(width);

		if(this.lastWidth !== width) {

			this.setState({
				resizeParentWidth: width + 'px'
			})

			this.lastWidth = width;
		}

	}

	onWindowResize = ()=>{
		this.setState(prevState=>{
			if( this.state.windowHeight === windowInfo.data.window.h){
				return null;
			}
			return {
				windowHeight: windowInfo.data.window.h
			}
		})
	}

	onResize = (dimensions, batch = true) => {
		
		if(
			(this.isAboveViewport ?? this.pageRef.current?.getBoundingClientRect().bottom < 1)
			|| (
				// page is loaded in through an upwards pagination call. We need to offset it's height
				// so the viewport stays in the same position after first rendering this page.
				this.props.position === 'above' 
				&& this.firstResizeOccurred !== true
			)
		) {

			aboveViewportHeightCompensationRequired += dimensions.height - this.currentHeight;

			if(!batch) {
				document.scrollingElement.scrollTop += aboveViewportHeightCompensationRequired;
				aboveViewportHeightCompensationRequired = 0;
			}

		}

		this.currentHeight = dimensions.height;
		this.firstResizeOccurred = true;

	}

	onAboveViewPort = (intersection) => {

		// filter out bunk intersection entries
		if(intersection.rootBounds.height === 0) {
			return;
		}

		this.isAboveViewport = intersection.boundingClientRect.bottom < 0;

	}

	onViewportBoundaryIntersection = (intersection) => {

		// by default the page should only render when not way outside 
		let shouldFullyRender = intersection.isIntersecting;

		// There are a few scenarios in which pages cannot be hidden
		if(
			// always render pages on the server
			helpers.isServer
		) {
			// force render
			shouldFullyRender = true;
		}

		this.setFullRenderState(shouldFullyRender);

	}

	setFullRenderState = (shouldFullyRender, callback) => {

		this.setState({
			fullyRender: shouldFullyRender,
			lastPageLayoutHeight: this.pageLayoutRef.current?.getBoundingClientRect().height || 100
		}, callback);

	}

	updateAdminState = (payload) => {
		store.dispatch({
			type: 'UPDATE_ADMIN_STATE', 
			payload: payload
		});
	}

	zIndexLayerPosition = (value) => {
		return value?.toString().padStart(2, '0');
	}

	findPageAdjusteeStatus = (pageID) => {

		let adjusteeData = {top: null, bottom: null}

		let adjustees = _.pickBy(this.context?.adjustPairs, (key) => { 
			return key.adjusts.indexOf(this.props.pid) !== -1; 
		});

		if (_.keys(adjustees).length > 0) {
			_.each(adjustees, function(adjustee){
				adjusteeData[adjustee.location]	= adjustee.adjustedHeight;
			})
		}

		return adjusteeData;
	}



	getPageInfoContextValue() {

		if(
			this.props.isEditingPage !== this.lastProps?.isEditingPage
			|| this.props.pid !== this.lastProps?.pid
		) {

			// only generate new context if needed
			this.pageInfoContextValue = {
				isEditing: this.props.isEditingPage,
				pid: this.props.pid
			}

		}

		this.lastProps = this.props;

		return this.pageInfoContextValue

	}

	onItemClick = (e)=>{

		if( e.button !== 2){
			return
		}

		if( !this.state.zoomedOut ){
			return
		}

		const mediaItem = e?.target.closest('media-item');

		if( !mediaItem){
			return;
		}

		e.preventDefault();


		this.setState({
			zoomedOut: false
		}, ()=>{
			setTimeout(()=>{
				mediaItem?.dispatchEvent(new CustomEvent('request-scroll', {
					bubbles: true,
					cancelable: true,
					composed: true,
					detail: {
						transitionTime: 0,
						preventQuickViewClose: true,
						lazyScroll: false,
					}
				}));

				mediaItem.classList.add('hey-listen');
				this.flashInterval = setInterval(()=>{

					if(mediaItem.classList.contains('hey-listen')){
						mediaItem.classList.remove('hey-listen');
					} else {
						mediaItem.classList.add('hey-listen');						
					}

				}, 300);



					setTimeout(()=>{
						mediaItem.classList.remove('hey-listen');						
						clearInterval(this.flashInterval);

					}, 1200);					

			}, 0);
		});

	

		console.log(e);
	}


	render() {

		if(!this.props.hasPage) {
			return null;
		}

		let pageJSX = null;

		const hasBackdrop = false;

		if(this.state.fullyRender) {


			// get all of the padding and margins together that we've gathered and use them to set a 'max fit height' for media items
			let pad = this.props.contentPad.top +
				this.state.pageLayoutPad.top +
				this.state.pageContentPad.top +
				this.state.pageContentPad.bottom +
				this.state.pageLayoutPad.bottom +
				this.props.contentPad.bottom;


			let maxFitHeight = Math.max(10, this.state.windowHeight - pad);
			let pageContent = null;

			if(this.props.access_level === 'password') {

				pageContent = <Password target={this.props.pid} onSucces={() => {
					this.props.fetchContent(this.props.pid, {
						force: true
					});
				}}/>

			} else {
				if(this.hasOwnProperty('lockedContent')) {
					// do not attempt to render page content twice. This is extremely important
					// for the admin so we can assume control over the page content. Bad things 
					// will happen if both preact and the admin modify this
					pageContent = this.lockedContent;
				} else {
					pageContent = this.lockedContent = this.props.content;
				}
			}


			pageJSX = (
				<>
					<PageInfoContextProvider
						pageRef={this.pageRef}
						value={this.getPageInfoContextValue()}
					>
						<style id="img-style">{`
							body.mobile .page-content{
								${this.state.zoomedOut ? 'padding: 1px 1px 8rem 1px;': 'padding: 2rem 2rem 8rem 2rem;'}
							}
							.page-content {
								${this.state.zoomedOut ? 'padding: 1px;': 'padding: 3rem 3rem 8rem 3rem;'}
							}
							body.mobile .caption {
								font-size: 3rem;
							}
							.caption {

							    margin-top: 1rem;
							    font-size: .75rem;
							    font-weight: 400;
							    color: rgba(0, 0, 0, 0.35);
							    font-style: normal;
							    line-height: 1.3;
							}

							.caption a {
							    color: rgba(0, 0, 0, 0.35);
								text-decoration:none;
							}

							.hey-listen::part(media){
			                	opacity: 0.3;
							}
									

							media-item::part(media){
								transition: opacity ease-in-out 0.1s;								
								outline: 1px solid rgba(0,0,0,0.08);
								outline-offset: -1px;
							}

							media-item:hover a.original {
								display: block;
							}
							.caption a.original {
								padding: 8px;
								margin: -8px;
								display:none;
								float: right;
							}

							.quick-view-background {
								background-color: rgba(21,21,21, 0.8);
							}

							body.mobile .sort-controls text-icon {
								height: 5rem;
								border: 1rem solid white;								
							}

							.sort-controls {
								display: ${this.state.authorized ? 'grid' : 'none'};
								position: fixed;
								bottom: 1.8rem;
								left: 1.4rem;
								z-index: 1000;
								column-gap: 1px;
								grid-template-columns: 1fr 1fr 1fr 1fr;
								filter: drop-shadow(0px 1px 2px rgba(0,0,0, 0.2));
							}

							.sort-controls text-icon.active {
								color: black;
							}

							.sort-controls text-icon:active {
								transform: translate3d(0px, 2px, 0px);
							}

							.sort-controls text-icon {
								user-select:none;
								transform: translate3d(0px, 0px, 0px);
								transition: .05s transform linear;
								color: #ccc;
								background-color: white;
								border: .5rem solid white;
								border-radius: 100%;
								height: 2.5rem;
								cursor: pointer;
							}

							.sort-controls text-icon.zoom {
								color: black;
							}

							${this.state.zoomedOut ? `
							media-item:hover .caption a.page-link {
								display: block;
							}

							media-item .caption {
								margin: 0;
								padding: 0;
								position: absolute;
								inset: 0;
								pointer-events:none;
							}
							media-item .caption a {
								display: none;
								color: transparent;
								pointer-events:auto;
							}

							media-item .caption a.page-link {
								pointer-events: auto;
								overflow: hidden;
								font-size: 0;

								border-style: solid;
								position: absolute;
								top: 0px;
								right: 0px;
								z-index: 99;
							}

							media-item .caption a.page-link::after {
								display: block;
								padding: 5px;
								content: '🔗';
								font-size: 14px;
								font-family:monospace;
								color: black;
								filter:saturate(0);
							}

							`: ''}
						`}</style>
						<div className="page-layout" ref={this.pageLayoutRef}>
							<div className="page-content" ref={this.pageContentRef}>
								<div className="sort-controls">
									<text-icon
										icon="downwards-arrow"

										onPointerDown={(e)=>{
											e.preventDefault();
											e.stopPropagation();

											this.setState({
												imageSort: 'forward'
											})
										}}
										className={this.state.imageSort === 'forward' ? 'active': ''}
									></text-icon>
									<text-icon
										icon="upwards-arrow"

										onPointerDown={(e)=>{
											e.preventDefault();
											e.stopPropagation();

											this.setState({
												imageSort: 'reverse'
											})
										}}
										className={this.state.imageSort === 'reverse' ? 'active': ''}
									></text-icon>
									<text-icon
										icon="shuffle"

										onPointerDown={(e)=>{
											e.preventDefault();
											e.stopPropagation();

											if( this.state.imageSort === 'shuffle'){
												this.galleryRef.current.dispatchEvent(new Event('refresh-thumbnail-index'));
											}  else {
												this.setState({
													imageSort: 'shuffle'
												})												
											}
											
										}}
										className={this.state.imageSort === 'shuffle' ? 'active': ''}
									></text-icon>
									<text-icon
										icon={this.state.zoomedOut ? 'expand-arrows': 'collapse-arrows'}

										onPointerDown={(e)=>{
											e.preventDefault();
											e.stopPropagation();

											this.setState({
												zoomedOut: !this.state.zoomedOut
											})	
											
										}}
										className='zoom'
									></text-icon>									

								</div>							
								<UsesWatcher
									pageInfo={this.getPageInfoContextValue()}
									adminMode={this.props.adminMode}
									bodycopyRef={this.bodycopyRef}
								>
									<CustomElementHost portalHost={this.bodycopyRef} />
									<bodycopy
										style={{
											'--fit-height': maxFitHeight + 'px',
											'--resize-parent-width': this.state.resizeParentWidth
										}}
										ref={this.bodycopyRef}
									>

										{this.state.authorized ? <gallery-justify
											onContextMenu={this.onItemClick}
											ref={this.galleryRef}
											thumbnail-index="img:all"
											sort={this.state.imageSort}
											row-height={this.state.zoomedOut ? '10vw': '15vw'}
											mobile-row-height={this.state.zoomedOut ? '200px': '100vh'}
											show-title={true}
											gutter={this.state.zoomedOut ? '1px' : '2rem 3rem'}>
										</gallery-justify> : null }
									</bodycopy>
								</UsesWatcher>
							</div>
						</div>

					</PageInfoContextProvider>
				</>
			)

		} else {
			pageJSX = <div style={{height: this.state.lastPageLayoutHeight + 'px'}}></div>
		}

		return (
			<div 

				page-url={this.props.purl?.toLowerCase()}
				className="page"
				ref={this.pageRef}
				editing={false}
			> 
				<a id={this.props.purl}></a>
				<ScrollContextProvider passthrough={false} scrollingElement={this.scrollingElement}>
					{pageJSX}
				</ScrollContextProvider>

				{/* the inline style tag for local CSS. Don't comment this out as the admin requires it for local style previewing */}

			</div>
		)

		
	}

	componentDidUpdate = (prevProps, prevState, prevContext) => {

		this.bindObservers();

		if(this.state.fullyRender === true && prevState.fullyRender === false) {

			// the page is fully rendered but was sparse before
			this.onFullComponentRender();

		} else if(this.state.fullyRender === false && prevState.fullyRender === true) {

			// the page is sparse but was fully rendered bfore
			this.onSparseComponentRender();

		}

		// ACTIVE LINKS
		// check active links as routing occurs
		if ((prevProps.activePURL !== this.props.activePURL)
			|| 
			// check when going in and out of preview
			(prevProps.adminMode !== this.props.adminMode)
		) {
			this.updateShowCartLinkCounts();
		}

		if( this.props.local_css !== prevProps.local_css ) {
			this.setMobileOffsets();
		}

		if(prevProps.isMobile !== this.props.isMobile) {
			this.setMobileOffsets();
		}

		if(this.props.hashPurl !== prevProps.hashPurl) {
			this.runHashScroll();
		}

	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {

		// Don't attempt to render if no props/state/context has changed
		if(
			this.props === nextProps 
			&& this.state === nextState
			&& this.context === nextContext
		) {
			return false;
		}

		if(
			// we're using locked content
			this.hasOwnProperty('lockedContent')
			// and content prop changed
			&& this.props.content !== nextProps.content
			// but everything else is still equal
			&& shallowEqual({...this.props, content: null}, {...nextProps, content: null})
			&& this.state === nextState
			&& this.context === nextContext
		) {
			// no need to render
			return false;
		}

		return true;

	}



	bindObservers = (willUnmount=false)=>{

		if( this.pageRef.current !== this.lastPageRef.current || willUnmount ){
			
			if( this.lastPageRef.current){
				pageMap.delete(this.lastPageRef.current);
				resizeObserver.unobserve(this.lastPageRef.current);
				aboveViewportObserver.unobserve(this.lastPageRef.current);
				viewportBoundaryObserver.unobserve(this.lastPageRef.current);
			}

			if( this.pageRef.current && !willUnmount){
				pageMap.set(this.pageRef.current, this);
				resizeObserver.observe(this.pageRef.current);
				aboveViewportObserver.observe(this.pageRef.current);
				viewportBoundaryObserver.observe(this.pageRef.current);
			}

			this.lastPageRef.current = this.pageRef.current;

		}

		if( this.pageLayoutRef.current !== this.lastPageLayoutRef.current || willUnmount ){

			if( this.lastPageLayoutRef.current ){
				pageLayoutMap.delete(this.lastPageLayoutRef.current);
				resizeObserver.unobserve(this.lastPageLayoutRef.current);
			}

			if( this.pageLayoutRef.current && !willUnmount){
				pageLayoutMap.set(this.pageLayoutRef.current, this);
				resizeObserver.observe(this.pageLayoutRef.current);
			}
			this.lastPageLayoutRef.current = this.pageLayoutRef.current;
		}

		if( this.pageContentRef.current !== this.lastPageContentRef.current || willUnmount ){

			if( this.lastPageContentRef.current){
				pageContentMap.delete(this.lastPageContentRef.current);
				resizeObserver.unobserve(this.lastPageContentRef.current);
			}
			if( this.pageContentRef.current && !willUnmount ){
				pageContentMap.set(this.pageContentRef.current, this);
				resizeObserver.observe(this.pageContentRef.current);
			}
			this.lastPageContentRef.current = this.pageContentRef.current;
		}

		if( this.bodycopyRef.current !== this.lastBodycopyRef.current || willUnmount ){

			if( this.lastBodycopyRef.current){
				bodycopyMap.delete(this.lastBodycopyRef.current);
				resizeObserver.unobserve(this.lastBodycopyRef.current);
			}

			if( this.bodycopyRef.current && !willUnmount){
				bodycopyMap.set(this.bodycopyRef.current, this);
				resizeObserver.observe(this.bodycopyRef.current);
			}

			this.lastBodycopyRef.current = this.bodycopyRef.current;

		}

	}

	runHashScroll() {

		if(this.props.hashPurl === this.props.purl) {

			if(this.state.fullyRender !== true) {
				// if page is rendered in sparse mode because it's far out of view:
				// render the page first, then scroll to it
				this.setFullRenderState(true, () => {
					this.pageRef.current.scrollIntoView();
				})
			} else {
				this.pageRef.current.scrollIntoView();
			}

		}

	}

	checkLocalStorage = ()=>{

		if ( localStorage.getItem('images-include-offline')  && !this.state.authorized ) {
			this.setState({
				authorized: true
			})
			clearInterval(this.checkStorageInterval);
		}		
	}

	componentDidMount() {

		this.checkStorageInterval = setInterval(()=>{
			this.checkLocalStorage();
		}, 180);

		var userStyles = document.body.querySelectorAll('style');
		userStyles.forEach(style=>{
			if( style.id !== 'img-style'){
				style.disabled = true;
			}
		});

		// disable the user styles twice because one style tag gets loaded + updated async
		setTimeout(()=>{
			userStyles.forEach(style=>{
				if( style.id !== 'img-style'){
					style.disabled = true;
				}
			});			
		}, 100);

		this.runHashScroll();

		windowInfo.on('window-resize', this.onWindowResize)
		
		if(this.state.fullyRender === true) {
			this.onFullComponentRender();
		}

		// let pin context know about our element instantly
		

	}

	onFullComponentRender() {
		
		if(this.pageLayoutRef.current) {

			function markNodeAsSaveable(node) {
				node.childNodes.forEach(node => node.setSaveable(true));
				for (const child of node.childNodes) {
					markNodeAsSaveable(child);
				}
			}

			markNodeAsSaveable(this.pageLayoutRef.current);

		}

		if(this.pageRef.current) {

			// handle scripts
			this.initializeScriptTags();
			this.props.pageMounted(this.pageRef.current).then(() => {

				// wait till the page mount call is complete. If we have a DOM binding for this 
				// page it'll sync the page to what's in the CRDT, overwriting any differences
				// that these functions might have caused
				this.updateShowCartLinkCounts();
				this.fetchCommerceProductsByLink();

			})
		}


		if(this.props.isMobile) {
			this.setMobileOffsets();
		}

		this.bindObservers();

	}

	componentWillUnmount() {

		clearInterval(this.checkStorageInterval);
		// disable all user-styles
		var userStyles = document.body.querySelectorAll('style');
		userStyles.forEach(style=>{
			style.disabled = false;
		});		


		windowInfo.off('window-resize', this.onWindowResize);

		this.onSparseComponentRender(true);

 		// manually resize to 0 height
		this.onResize({ height: 0 }, false);

		// let pin context know instantly
		

	}

	onSparseComponentRender(willUnmount=false) {

		this.bindObservers(willUnmount);

		if(this.pageRef.current) {
			this.props.pageUnMounted(this.pageRef.current);
		}

	}

	fetchCommerceProductsByLink = () => {

		if(!this.pageRef.current) {
			return;
		}

		if (!this.props.has_shop) return;

		let links = this.pageRef.current.querySelectorAll('[rel="add-to-cart"], [rel="add_to_cart"]');
		let linkedProductIDs = [];

		_.each(links, (link) => {
			let productID = link.getAttribute("data-product");

			if (productID) {
				linkedProductIDs.push(productID);
			}
		})

		this.props.fetchCommerceProducts({
			idArray: linkedProductIDs,
			onlyNew: true
		})
	}

	updateShowCartLinkCounts = () => {

		if(!this.pageRef.current) {
			return;
		}

		// having a shop is required
		if (!this.props.has_shop) return;
		// if no data in the cart, there's no count to update
		if (!this.props.cartData || _.isEmpty(this.props.cartData)) return;
		// do not update cart count text in admin mode
		if (this.props.adminFrame) return;


		let cartCount = _.keys(this.props.cartData).length;
		let links = this.pageRef.current.querySelectorAll('a[rel="show-cart"], a[rel="show_cart"]');

		_.each(links, (link) => {
			if (link.hasAttribute("show-count")) {
				let unTouchedText = link.innerHTML.replace(/ *\([^)]*\) */g, "");
				link.innerText = unTouchedText + ' ('+cartCount+')';
			}
		})
		
	}

	initializeScriptTags = () => {

		let errorOccured = false;
		const onError = function(e){
			errorOccured = true;
		}

		window.addEventListener('error', onError, false);

		_.each(this.bodycopyRef.current?.querySelectorAll('script'), script => {

			// these are non-executable scripts - pass over them
			if( script.getAttribute('type') === 'text/thumbnail-metadata'){
				return;
			}

			// clear any unrelated errors
			errorOccured = false;

			// need to create a new script node for it to be executed
			const newScriptNode = document.createElement('script');

			// copy over attributes
			[...script.attributes].forEach( attr => { 
				newScriptNode.setAttribute(attr.nodeName, attr.nodeValue) 
			})

			// set script body
			newScriptNode.innerHTML = script.textContent;

			// insert it
			script.replaceWith(newScriptNode);

			if(errorOccured) {
				
				// reset
				errorOccured = false;

				// replace script with a commented out version
				newScriptNode.parentNode.insertBefore(document.createComment(newScriptNode.outerHTML), newScriptNode);
				newScriptNode.remove();

				if(this.props.adminMode) {
					// alert('One or more scripts on this page are broken and were disabled.');
					window.store.dispatch({
						type: 'UPDATE_FRONTEND_STATE', 
						payload: {
							alertModal: { 
								message: 'One or more scripts on this page are broken and were disabled.',
								type: 'notice'
							}
						}
					});
				}

			}

		});

		window.removeEventListener('error', onError);

	}

}

SiteImages.defaultProps = {
	contentPad: {
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	}
}

SiteImages.contextType = PinManagerContext;

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		pageMounted         : actions.pageMounted,
		pageUnMounted       : actions.pageUnMounted,
		updateFrontendState : actions.updateFrontendState,
		fetchContent        : actions.fetchContent,
		fetchCommerceProducts: actions.fetchCommerceProducts,
	}, dispatch);

}


export default connect(
	(state, ownProps) => {

		const page = {
			id: 'img',
			purl: '/img',
			pin: false,
			title: 'Images',
			backdrops: {},
			content: '',
			access_level: 'public', 
		}

		let activePURL = (state.pages.byId[state.frontendState.activePID] || state.sets.byId[state.frontendState.activePID])?.purl;

		if(!helpers.isServer && !activePURL) {
			activePURL = window.location.pathname.replace(/^\//, '').toLowerCase();
		}

		// lowercase the activePURL to avoid case sensitivity
		if(typeof activePURL === "string") {
			activePURL = activePURL.toLowerCase();
		}

		return {
			inAdminFrame	: false,
			isMobile 		: state.frontendState.isMobile,
			adminMode		: false,
			isEditingPage 	: false,
			hasPage 		: page !== undefined,
			pid				: page?.id,
			purl			: page?.purl,
			isPin			: page?.pin,
			title			: page?.title,
			backdrops		: page?.backdrops,
			content			: page?.content,
			access_level	: page?.access_level,
			local_css		: page?.local_css,
			pin_options		: page?.pin_options,
			PIDBeingEdited 	: state.frontendState.PIDBeingEdited,
			activePID		: state.frontendState.activePID,
			activePURL		: activePURL,
			pageDepth		: selectors.getParentSetList(state, ownProps.id).length -1,
			has_shop		: state.site.shop_id !== null,
			cartData		: state.commerce?.cart,
			Editor_PageEditorOutlines: state.adminState?.localStorage?.Editor_PageEditorOutlines,
		}
	},
	mapDispatchToProps
)(
	SiteImages
)

import isPromise from 'is-promise';
import _ from 'lodash';
import { helpers } from "@cargo/common";

export default function errorMiddleware() {
	return next => action => {

		// If not a promise, continue on
		if (!isPromise(action.payload)) {
			return next(action);
		}

		 action.payload.catch(function (e) {

			// handle error
			if(helpers.isServer && e.response) {
				console.error('Request to', e.response.config.url, 'failed with code', e.response.status);
			} else {
				if(e && e.response && e.response.status === 401) {
					console.warn('No auth for request to', e.response.config.url);
					return;
				}
				console.error(e)
			}

			return e;

		});

		return next(action);
	};
}
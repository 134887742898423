import { createDynamicMiddlewares } from 'redux-dynamic-middlewares'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import merge from 'lodash/merge';
import { actions } from "./actions";

import errorMiddleware from "./middleware/xhrError";
import apiMiddleware from "./middleware/api";
import reducers from "./reducers";

import { helpers } from "@cargo/common";

export default () => {

	const enhancers = [];
	const dynamicMiddlewaresInstance = createDynamicMiddlewares()

	const middleware = [
		thunk,
		errorMiddleware, 
		apiMiddleware(), 
		dynamicMiddlewaresInstance.enhancer
	];

	const composedEnhancers = compose(
		applyMiddleware(...middleware),
		...enhancers
	);

	// Do we have preloaded state available? Great, save it.
	const initialState = !helpers.isServer ? window.__PRELOADED_STATE__ || {} : {};

	// Create the store
	const store = createStore(
		combineReducers(reducers),
		initialState,
		composedEnhancers
	);

	if (!helpers.isServer) {
		window.store = store;
		
		// Delete it once we have it stored in a variable
		delete window.__PRELOADED_STATE__;

	}

	return {
		store
	};
};
import { Component , cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from 'preact/compat'
import { ADMIN_FRAME } from "../../globals";
import EditorOverlay from "./editor-overlay";
import { editorOverlayAPI } from "./editor-overlay-controller"
import SelectionStatus from "./selection-status";

import _ from 'lodash';



class MarqueeEditor extends Component {
	constructor(props){
		super(props);

		this.state= {
			UIWindow: false,
			marqueeIsEmpty: false,
			draggingInEditor: false,
		}

		this.observer = new MutationObserver(this.onMutation);
		this.innerObserver = new MutationObserver(this.onInnerMutation);
	}

	render(props,state){

		const {

		} = props;

		const {
			UIWindow
		} = state;

		const buttonHeight = 23;

		return (
			<>
				<EditorOverlay
					hasFocus={!!UIWindow}
					trackResize={true}
					buttonMode="inside"
					baseNode={this.props.marqueeInstance}
					buttonHeight={23}
					render={(overlayProps)=>{

						const {
							pointerAttention,
							overlayPosition
						} = overlayProps;

						let dip = 0;
						if( overlayPosition.y <= 1 ){
							dip = Math.min(-overlayPosition.y +1, overlayPosition.height + -buttonHeight +1);
						}				

						return <>
						<SelectionStatus baseNode={this.props.marqueeInstance} overlayPosition={overlayPosition} overlayMode={true}/>
						<div
							className={`editor-overlay editor-outline${(pointerAttention || !!UIWindow) ? ' hover': ''}${(pointerAttention || !!UIWindow) ? ' button-hover': ''}${!!UIWindow ? ' focus': ''}`}
							style={{
								transform: `translate3d(${overlayPosition.x}px, ${overlayPosition.y}px, 0)`,
								width: overlayPosition.width +'px',
								height: overlayPosition.height+'px',
								display: (pointerAttention || !!UIWindow) ? 'block': 'none',
								minHeight: '27px'
							}}
						>
							<div
								className="in-editor-buttons marquee"
								style={{
									transform: `translateY(${dip}px)`
								}}
								onPointerEnter={()=>{this.setState({buttonHovered: true})}}
								onPointerLeave={()=>{this.setState({buttonHovered: false})}}
							>
								<button
									onMouseDown={(e)=>{this.launchUIWindow(e)}}
									className="text"
								>
									Marquee
								</button>
								{props.children}
							</div>
							{props.otherUI}
						</div>
						</>
				}}/>

			</>)
	}

	launchUIWindow = (e)=>{

		if (e) {
			if (e.metaKey || e.button == 2){
				return;
			}

			e.preventDefault();
			e.stopPropagation();
		}

		const marqRect = this.props.marqueeInstance.getBoundingClientRect();
		const svgPosition = {
			x: marqRect.x + -10,
			y: marqRect.y,
			left: marqRect.right + -10,
			right: marqRect.right + -10,
			top: marqRect.top + 0,
			bottom: marqRect.top + 27,
			width: marqRect.width,
			height: 10
		}

		ADMIN_FRAME.adminWindow.UIWindowOpener.openUIWindow({
			windowName: 'marquee-ineditor',
			windowAlias: 'marquee',
			positionRect: svgPosition,
			closeButton: false,
			closeOnAllClickout: true,
			supportsMobile: false,
			props: {
				activeMarquee: this.props.marqueeInstance
			}
		});		
	}

	onMouseDown=(e)=>{
		
		if( this.state.UIWindow || e.button === 2){
			return;
		}

		let coords = {
			x: e.clientX,
			y: e.clientY
		}

		// do this on a slight delay in case we've started dragging
		if( this.props.marqueeInstance.built ){
			this.mouseDownTimeout = setTimeout(()=>{

				if( this.props.isDragging){
					return;
				}

				this.props.marqueeInstance.built = false;

				setTimeout(()=>{

					let marqueeInner = this.props.marqueeInstance.querySelector('marquee-inner');
					let insertionRange = CargoEditor.helpers.createRangeAtCoordinates(coords.x, coords.y);

					if(
						!insertionRange || !marqueeInner.contains(insertionRange.commonAncestorContainer)
					){
						insertionRange = CargoEditor.rangy.createRange();
						insertionRange.setStart(marqueeInner, 0)
						insertionRange.setEnd(marqueeInner, 0);					
					}

					CargoEditor.helpers.setActiveRange(insertionRange);
					CargoEditor.events.trigger('cursor-activity', CargoEditor.getActiveEditor(), e);

				}, 20);	

			},120);
		}

	}

	onInnerMutation = (mutations)=>{
		let mutationList = Array.from(mutations).filter(mutation=>{
			if( mutation.type === 'attributes'){

				if(  mutation.target.nodeName==='MEDIA-ITEM'){

					return true;

				} else if (mutation.target.nodeName ==='MARQUEE-INNER'){

					return false
				}

			} else {

				return !mutation.target.closest?.('media-item') ?? true;
			}
		})

		if( mutationList.length == 0){
			return;
		}

		let marqueeInner = Array.from(this.props.marqueeInstance.querySelectorAll('marquee-inner')).find(inner=>{
			return !inner.hasAttribute('slot') || inner.getAttribute('slot') === 'contents'
		});	

		if( !marqueeInner ) {
			return
		}

		let checkNode = Array.from(marqueeInner?.childNodes ?? [])[0];

		let isEmpty = true;
		while (checkNode && isEmpty){

			if(
				checkNode.nodeName ==='IMG' ||
				checkNode.nodeName ==='VIDEO' ||
				checkNode.nodeName ==='IFRAME' ||
				checkNode.nodeName ==='MEDIA-ITEM' 
			) {
				isEmpty = false;
			} else if(checkNode.textContent.trim() !== ''){
				isEmpty = false;
			}

			checkNode = checkNode.nextSibling;
			
		}

		this.setState({
			marqueeIsEmpty: isEmpty 
		});
		
		

	}

	onMutation = (mutations)=>{
		this.observer.disconnect();
		this.innerObserver.disconnect();

		// only observe a fully built marquee instance
		let marqueeInner = Array.from(this.props.marqueeInstance.querySelectorAll('marquee-inner')).find(inner=>{
			return !inner.hasAttribute('slot') || inner.getAttribute('slot') === 'contents'
		});

		let marqueeInnerClone = this.props.marqueeInstance.querySelector('marquee-inner[slot="contents-clone"]');

		if( marqueeInner && marqueeInnerClone ){

			// remove all gunk not slotted into the shadow dom
			Array.from(this.props.marqueeInstance.childNodes).forEach(node=>{
				if(node !== marqueeInner && node !== marqueeInnerClone){
					node.remove();
				}
			})

		} else if ( marqueeInner && !marqueeInnerClone ){

			this.props.marqueeInstance.dirty = true;
			
		}

		this.observer.observe(this.props.marqueeInstance, {childList: true, subtree: false, characterData: true, attributes: false});
		this.innerObserver.observe(marqueeInner, {childList: true, subtree: true, characterData: true, attributes: true})			

	}

	onDragStart = (editor, e, dragData)=>{
		this.setState({
			draggingInEditor: true,
		})
		editorOverlayAPI.activateHoverElement(this.props.marqueeInstance);

		this.props.marqueeInstance.built = false

	}

	onDragFinish = ()=>{
		this.dragFinishTimeout = setTimeout(()=>{
			this.setState({
				draggingInEditor: false,
			})			
		}, 100)

		this.props.marqueeInstance.built = true;	
	}

	setUIWindow = (UIWindow)=>{
		this.setState({
			UIWindow,
		})

	}

	updateUIWindowPosition = (scrollDelta)=>{

		if(!this.state.UIWindow){
			return;
		}

		this.state.UIWindow.props.superBadScrollHack.scroll(scrollDelta);

	}

	componentDidMount(){



		this.observer.observe(this.props.marqueeInstance, {childList: true, subtree: false, characterData: true, attributes: false});

		// only observe a fully built marquee instance
		let marqueeInner = Array.from(this.props.marqueeInstance.querySelectorAll('marquee-inner')).find(inner=>{
			return !inner.hasAttribute('slot') || inner.getAttribute('slot') === 'contents'
		});

		if( marqueeInner){
			this.innerObserver.observe(marqueeInner, {childList: true, subtree: true, characterData: true, attributes: true})					
		}

		this.props.marqueeInstance.addEventListener('mousedown', this.onMouseDown);

		ADMIN_FRAME.globalDragEventController.on('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.on('dragend', this.onDragFinish);
		ADMIN_FRAME.globalDragEventController.on('drop', this.onDragFinish)

		this.props.marqueeInstance._editorInterface = this;

		if( this.props.marqueeInstance._newMarqueeSet ){
			delete this.props.marqueeInstance._newMarqueeSet;
			setTimeout(()=>{
				this.launchUIWindow();
			}, 120)

		}			
	}

	componentWillUnmount(){

		clearTimeout(this.mouseDownTimeout);		
		clearTimeout(this.dragFinishTimeout);
		this.observer.disconnect();
		this.innerObserver.disconnect();
		this.props.marqueeInstance.removeEventListener('mousedown', this.onMouseDown);

		ADMIN_FRAME.globalDragEventController.off('dragstart', this.onDragStart)
		ADMIN_FRAME.globalDragEventController.off('dragend', this.onDragFinish);
		ADMIN_FRAME.globalDragEventController.off('drop', this.onDragFinish);

		this.state.UIWindow?.closeWindow?.();

		delete this.props.marqueeInstance._editorInterface
	}

	componentDidUpdate(prevProps, prevState){

		if( this.props.adminMode !== prevProps.adminMode){
			this.props.marqueeInstance.dirty = true;
		}

		// if we start/stop dragging before the mousedown timeout completes, cancel it 
		if( this.props.isDragging !== prevProps.isDragging ){
			clearTimeout(this.mouseDownTimeout)
		}
		if(
			prevState.marqueeIsEmpty !== this.state.marqueeIsEmpty ||
			prevState.draggingInEditor !== this.state.draggingInEditor
		) {
			if ( !this.state.draggingInEditor && this.state.marqueeIsEmpty ){
				CargoEditor?.mutationManager?.execute(()=>{
					this.props.marqueeInstance.remove();
				})
			}
		}
	}

}


export default MarqueeEditor
